.App {
    /* text-align: center; */
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* .mainContainer {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
} */

.mainContainer {
    text-align: center;
    justify-content: center;
    display: flex;
    padding: 250px 0 90px 0;
}

.subMainContainer {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 40vh;
    width: 25%;
    border-style: groove;
    border-color: #282c34;
    border-radius: 60px;
    padding-top: 60px;
    background-color: white;
}

.titleContainer {
    font-size: 64px;
    float: inline-end;
}

.resultContainer,
.historyItem {
    flex-direction: row;
    display: flex;
    width: 400px;
    align-items: center;
    justify-content: space-between;
}

.historyContainer {
    flex-direction: column;
    display: flex;
    height: 200px;
    align-items: center;
    flex-grow: 5;
    justify-content: flex-start;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 260px;
}

.loginInput {
    width: 18vw;
    height: 5vh;
    font-size: 18px;
}

.loginButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 8vh;
}

.tabTitle {
    font-size: 2.8em;
    font-weight: 500;
}

.searchBar {
    width: 14vw;
    height: 40px;
    font-size: 18px;
    margin-right: 1vw;
}

.statusBar {
    width: 7vw;
    font-size: 18px;
    margin-right: 1vw;
}

.permissionBar {
    width: 10vw;
    font-size: 18px;
    margin-right: 1vw;
}

.projectBar {
    width: 10vw;
    font-size: 18px;
    margin-right: 1vw;
}

.gateTypeBar {
    width: 180px;
    font-size: 18px;
    margin-right: 1vw;
}

.resetButton {
    width: 5vw;
    height: 40px;
    margin-right: 1vw;
}

#projectSelect .ant-select-selector {
    height: 4vh !important;
    align-items: center !important;
    font-size: 15px;
}

#projectSelect .ant-select-selection-item {
    font-weight: bold;
}

.selectProjectButton {
    width: 10vw;
    height: 4vh;
    margin-left: 1vw;
}

.addNewButton {
    width: 7vw;
    height: 4vh;
    font-size: 15px;
    margin-left: auto;
}

.actionButton {
    /*  */
}

.modalTitle {
    font-size: 25px;
}

.modalSubmitButton {
    float: right;
}

.ant-input-disabled {
    color: black !important;
}

.ant-input-number-disabled {
    color: black !important;
}

.ant-select-disabled:where(
    .css-dev-only-do-not-override-14wwjjs
  ).ant-select:not(.ant-select-customize-input)
.ant-select-selector {
    color: black !important;
}

.ant-input-number-group-wrapper
.ant-input-number-wrapper-disabled
> .ant-input-number-group-addon {
    color: black !important;
}

